import * as React from "react"
import * as config from "../../constants"

export const ArticleFooter = ({ link, title }: ArticleFooterProps) => (
  <footer className="">
    <section className="">
      <figure className=" box-border block float-left border-full z-10 mr-8 overflow-hidden" style={{width: '120px', height: '120px'}}>
        <span
          className="relative block w-full h-full bg-cover bg-center rounded-full"
          style={
            {
              backgroundImage: "url(/img/profile-1.jpg)",
            }
          }
        >
          <span className="hidden">{config.AUTHOR}'s Picture. Just imagine something really nice &amp; it'll be a win win for both of us.</span>
        </span>
      </figure>
      <div className="bio">
        <div className="font-bold mb-0">{config.AUTHOR}</div>
        <div className="">
          {config.BIO}
        </div>
      </div>
    </section>
  </footer>
)

export default ArticleFooter

export interface ArticleFooterProps {
  link: string
  title: string
}