import * as React from "react"
import * as config from "../../constants"
import { DiscussionEmbed } from 'disqus-react';

class Comments extends React.Component<CommentProps, CommentState>{
  constructor(props: CommentProps) {
    super(props);
  }

  render() {
    const { title, category_id, link } = this.props;
    if (!config.DISQUS) {
      return null;
    }
    return (
      <div className="mt-16 p-4 dark:bg-black light:bg-white dark:text-white light:text-black">
        <DiscussionEmbed
          shortname={config.DISQUS}
          config={
            {
              url: config.DOMAIN + link,
              identifier: title,
              title: title,
              language: 'en_US' //e.g. for Traditional Chinese (Taiwan)	
            }
          }
        />
      </div>
    );
  }

}

export default Comments

export interface CommentProps {
  title: string
  category_id: string
  link: string
}

export interface CommentState {
  toasts: CommentToasts[]
  mobile: boolean
}

export interface CommentToasts {
  text: string
}