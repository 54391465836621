import * as React from "react"
import { Link } from "gatsby"
import { createLinkToTag } from "../../helpers"

export const ArticleTags = ({ tags }: { tags: string[] }) => (
    <section className="w-full article-tags mt-10">
        {tags && tags.map((tag, i) => (
            <span className="" key={i}>
                <Link to={createLinkToTag(tag)}>{tag}</Link>
                {i !== (tags.length - 1) && <span className="px-2"> | </span>}
            </span>
        ))}
    </section>
)

export default ArticleTags