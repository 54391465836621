import * as React from "react"
import ShareButton from "../ShareButton"


export const ArticleShare = ({ link, title }: ArticleShareProps) => (
  <section>
    <h4 className="text-3xl">One Last Thing...</h4>
    <p>If you have a question or see a mistake, please comment below.</p>
    <p>If you found this post helpful, please share it with others. It's the best thanks I can ask for &amp; it gives me momentum to keep writing!</p>
    <ShareButton shareTo="twitter" link={link} title={title} />
    <ShareButton shareTo="linkedin" link={link} title={title} />
    <ShareButton shareTo="reddit" link={link} title={title} />
    <ShareButton shareTo="facebook" link={link} title={title} />
    <ShareButton shareTo="hacker-news" link={link} title={title} />
  </section>
)

export default ArticleShare

export interface ArticleShareProps {
  link: string
  title: string
}