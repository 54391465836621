import React from 'react'
import { TemplateWrapper } from "../layouts"
import PageTitle from "../components/PageTitle"

const NotFoundPage = () => (
  <TemplateWrapper>
    <article className="main-article mt-10 lg:mx-6 lg:w-8/12 w-full flex-grow">
      <PageTitle>NOT FOUND</PageTitle>
      <p>Sorry, this page doesn't exist.</p>
    </article>
  </TemplateWrapper>
)

export default NotFoundPage
