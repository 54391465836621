import * as React from "react"
import { Helmet } from "react-helmet"
import Link from "gatsby-link"
import { padStart } from "lodash"
import NotFoundPage from "../pages/404"
import SideBar from "../components/SideBar"
import ArticleTags from "../components/ArticleTags"
import ArticleFooter from "../components/ArticleFooter"
import * as config from "../constants"
import { createLinkToTag } from "../helpers"
import Comments from "../components/Comments"
import ArticleShare from "../components/ArticleShare"
import { graphql } from 'gatsby'
import { TemplateWrapper } from "../layouts"
import PageTitle from "../components/PageTitle"

export const Content = ({ content, className }) => <div className={className}>{content}</div>

export const HTMLContent = ({ content, className }) => <div
  className={className}
  dangerouslySetInnerHTML={{ __html: content }}
/>

export const BlogPostTemplate = ({ content, contentComponent, description, title, date, modifiedDate, tags, path, cover }: BlogPostTemplateProps) => {
  const PostContent = contentComponent || Content
  const d = new Date(date)
  const firstTag = (tags !== null && tags.length > 0) ? tags[0] : config.DOMAIN
  const dateTime = d.getFullYear().toString() + "-" +
    padStart((d.getMonth() + 1).toString(), 2, "0") + "-" +
    padStart(d.getDate().toString(), 2, "0")
  const coverImage = cover ?
    `${config.DOMAIN}${cover}` :
    `${config.DOMAIN}/img/twitter-default.png`

  //TODO: switch to blogposting 
  // https://schema.org/BlogPosting
  const googleStructuredData: ArticleStructuredData = {
    "@context": "http://schema.org",
    "@type": "Article",
    "name": title,
    "headline": title,
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": config.DOMAIN
    },
    "author": {
      "@type": "Person",
      "name": config.AUTHOR
    },
    "datePublished": dateTime,
    "dateModified": modifiedDate,
    "description": description,
    "publisher": {
      "@type": "Person",
      "name": config.AUTHOR,
      "logo": {
        "@type": "ImageObject",
        "url": `${config.DOMAIN}/img/publisher-logo.gif`
      }
    }
  }

  if (cover) {
    googleStructuredData.image = coverImage
  }

  return (
    <div>
      <Helmet
        title={`${title} | ${config.SITE_TITLE}`}
        meta={[
          { name: "description", content: description },
          { property: "og:type", content: "website" },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:url", content: config.DOMAIN },
          { property: "og:image", content: coverImage },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:title", content: title },
          { name: "twitter:description", content: description },
          { name: "twitter:url", content: config.DOMAIN },
          { name: "twitter:image", content: coverImage },
        ]}
      />
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(googleStructuredData)}</script>
      </Helmet>

      <article className={`main-article page-${path.replace('/','')}`}>
        <header className="mb-8">
          <PageTitle>{title}</PageTitle>
          <section className="post-meta text-gray-500 dark:text-gray-300 italic mt-2">
            <time className="" dateTime={dateTime}>Published {date}</time>
          </section>
        </header>
        <PostContent content={content} />

        
        <section className="dark:bg-slate-900 bg-slate-200 px-8 py-8 mt-20">
          <ArticleShare
            link={`${config.DOMAIN}${path}`}
            title={title}
          />
          <ArticleTags tags={tags} />
        </section>

        <div className="my-20">
          <ArticleFooter
            link={path}
            title={title}
          />
        </div>

        <Comments
          link={path}
          title={title}
          category_id={firstTag}
        />
      </article>
    </div>
  )
}

const Post = ({ data }) => {
  if (typeof data === "undefined") {
    return NotFoundPage
  }
  const { markdownRemark: post } = data
  const tags = post.frontmatter.tags ? post.frontmatter.tags : []
  return (
    <TemplateWrapper>
      <div className="mt-10 lg:mx-6 lg:w-9/12 w-full flex-grow">
        <BlogPostTemplate
          content={post.html}
          contentComponent={HTMLContent}
          description={post.frontmatter.description}
          date={post.frontmatter.date}
          modifiedDate={post.frontmatter.modifiedDate}
          title={post.frontmatter.title}
          path={post.frontmatter.path}
          tags={post.frontmatter.tags}
          cover={post.frontmatter.cover}
        />
      </div>
      <SideBar tags={tags.concat(["misc"])} sideBarClosed={true} />
    </TemplateWrapper>
  )
}

export default Post

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        date(formatString: "MMMM D, YYYY")
        modifiedDate(formatString: "YYYY-MM-DD")
        title
        description
        cover
        tags
      }
    }
  }
`
export interface BlogPostTemplateProps {
  content: any
  contentComponent: any
  description: string
  title: string
  date: string
  modifiedDate: string
  tags: string[] | null
  path: string
  cover: string
}

export interface ArticleStructuredData {
  "@context": string
  "@type": string
  "mainEntityOfPage": {
    "@type": string
    "@id": string
  }
  "name": string
  "headline": string
  "author": {
    "@type": string
    "name": string
  },
  "datePublished": string
  "dateModified": string
  description: string
  image?: string
  "publisher": {
    "@type": string
    "name": string
    "logo": {
      "@type": string
      "url": string
    }
  }
}