import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSquare, faRssSquare, faS, faLink } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faGithub, faHackerNews, faLinkedin, faReddit, faStackOverflow, faTwitter, faTwitterSquare } from '@fortawesome/free-brands-svg-icons'

const ShareButton = ({ shareTo, link, title }: ShareButtonProps) => {
    const getShareLink = () => {
        switch (shareTo) {
            case "twitter":
                return `https://twitter.com/share?text=${title}&url=${link}`
            case "linkedin":
                return `https://www.linkedin.com/shareArticle?mini=true&url=${link}`
            case "reddit":
                return `https://reddit.com/submit?title=${title}&url=${link}`
            case "facebook":
                return `https://www.facebook.com/sharer/sharer.php?u=${link}`
            case "hacker-news":
                return `https://news.ycombinator.com/submitlink?t=${title}&u=${link}`
        }
    }
    const getWindowSize = () => {
        switch (shareTo) {
            case "twitter":
                return `width=550,height=235`
            case "linkedin":
                return `width=490,height=530`
            case "reddit":
                return `width=580,height=296`
            case "facebook":
                return `width=580,height=296`
            case "hacker-news":
                return `width=580,height=296`
        }
    }

    const ShareClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        event.preventDefault()
        if (typeof window !== "undefined") {
            window.open(getShareLink(), `${shareTo}-share`, getWindowSize())
        }
    }

    const GetComponent = () => {

        switch (shareTo) {
            case "twitter":
            default:
                return faTwitter
            case "linkedin":
                return faLinkedin
            case "reddit":
                return faReddit
            case "facebook":
                return faFacebook
            case "hacker-news":
                return faHackerNews
        }
    }

    return (
        <button type="button" onClick={ShareClick} title={title} className="inline-block bg-cyan-800 rounded p-2 mr-4 leading-none">
            <FontAwesomeIcon icon={GetComponent()} size="xs" className="text-white pr-2" />
            <span className="text-white text-base">Update</span>
        </button>
    )
}

export default ShareButton

export interface ShareButtonProps {
    shareTo: string
    link: string
    title: string
}